import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import PageHeader from "../../components/layout/PageHeader"
import SupportPage from "../../components/layout/SupportPage"
import GoogleImage from "../../components/GoogleImage"
import PDFDownloadBox from "../../components/PDFDownloadBox"

import { storage } from "../../settings"

const SolarPage = () => {
	return (
		<SupportPage page="resources" title="Solar Sign Lighting instructions">
			<PageHeader title="Solar Lighting Instructions" />

            <PDFDownloadBox
				title="Download Installation Instructions"
                pdfUrl="documents/Solar-Sign-Lighting-Instructions.pdf"
                imageUrl="SignTypes/solar-sign-lighting-instructions-thumb.jpg"
            />
    
            <p>
                Since all our lit sign products are lit with low voltage LEDs, it is a simple conversion to Solar Power your sign. This 
                can be something you are doing for environmental conservation or to install a Lit Sign where there is no utility power 
                source. Either way, we can help you to make this happen.                
            </p>

            <p>
                <GoogleImage src="Documents/solar-01.jpg" alt="Solar Installation Diagram" style={{display:"block", margin:"20px auto", maxHeight:"350px"}} />
            </p>

            <h3>Purchasing you sign:</h3>

            <p>
                All our lit products can be "solar powered" including 
                <Link to="/products/face-lit-channel-letters-and-logos/">EZLit Plug-n-Play Channel Letters</Link>, 
                <Link to="/products/face-lit-channel-letters-and-logos-on-raceway/">Raceway Mounted Channel Letters</Link> and 
                <Link to="/products/lit-shape-sign/">Lit Shape Signs</Link>. Pay attention to the size and estimated wattage of your sign. The higher the wattage 
                the larger the solar panels and battery that will be required.
            </p>
            <p>
                Our customer service team would be happy to guide you through this process.        
            </p>

            <h3>Parts for Solar Conversion:</h3>

            <ul>
                <li>
                    <strong>SignMonkey.com Lit Sign</strong>
                </li>
                <li>
                    <GoogleImage src="Documents/solar-morningstar.jpg" alt="Morningstar Sunlight 10/20" style={{float:"right", margin:"15px;"}} />

                    <strong>Morningstar Sunlight 10/20</strong>
                    <p>Lighting Controller with LVD</p>
                    <p>One 20 amp Lighting Controller will run up to 240 watts of sign load. These controllers can be ran in parallel if your sign is too large for one unit.</p>

                    <ul>
                        <li><a href="https://www.altestore.com/store/charge-controllers/solar-lighting-controllers/morningstar-sunlight-sl-10l-12v-10a-lighting-controller-with-lvd-p795/" target="_blank">Buy 10 amp MS Controller</a> for 12 volt Letters and Shapes</li>
                        <li><a href="https://www.altestore.com/store/charge-controllers/solar-lighting-controllers/morningstar-sunlight-sl-20l-12v-20a-lighting-controller-with-lvd-p797/" target="_blank">Buy 20 amp MS Controller</a> for 12 volt Letters and Shapes</li>
                        <li>Link: <a href="https://www.morningstarcorp.com/products/sunlight/" target="_blank">Sunlight Light Controller Product Information</a></li>
                        <li>Link: <a href="https://2n1s7w3qw84d2ysnx3ia2bct-wpengine.netdna-ssl.com/wp-content/uploads/2014/02/SunLight-Datasheet-ENG_R4_1_12.pdf" target="_blank">Sunlight - 10 or 20 Data Sheet</a></li>
                        <li>Link: <a href="https://2n1s7w3qw84d2ysnx3ia2bct-wpengine.netdna-ssl.com/wp-content/uploads/2014/02/SL.IOM_.Operators_Manual.01.ENG_.pdf" target="_blank">Sunlight - 10 or 20 Manual</a></li>
                        <li>Link: <a href="http://support.morningstarcorp.com/search/?document_section=&amp;search_product=114" target="_blank">Support Documents</a></li>
                    </ul>

                    <p>
                        If your sign is over 240 watts but less than 480 watts you can parallel the Controllers with more than 
                        one panel. <a href="http://support.morningstarcorp.com/wp-content/uploads/2014/07/ALL.DIG_.Parallel_Controllers.01.EN_.pdf" target="_blank">240w + Instructions</a>
                    </p>
                </li>
                <li>
                    <strong>Solar Panels</strong>
                    <p>
                        I have not found a significant difference between solar panels brands. I would suggest a Polycrystalline panel or 
                        Monocrystalline Panels, the Wattage of the panels should be about 4 or more times the Wattage of your sign. Do not 
                        use panels that exceed 30 volts and 240 watts each.
                    </p>
                </li>
                <li>
                    <strong>Mounting Structure</strong>
                    <p>
                        Depending on your location, there are mounting options to mount to a single pole, sloped roof or flat roof. If you 
                        have a specialty situation, we can assist you in building a custom mounting structure.                         
                    </p>
                    <p>
                        <a href="https://www.altestore.com/search/go?p=Q&amp;lbc=altenergystore&amp;uid=284506412&amp;ts=v3&amp;w=mounting&amp;isort=score&amp;method=and&amp;view=grid&amp;af=cat1:solarpanelmounts" target="blank">
                            Shop for Solar Panel Mounting Structures
                        </a>
                    </p>
                    <p>
                        Of course you will want to face your panels south and you can determine the angle from this chart. Instead of moving your 
                        solar panels at different times of the year, you can just use the average for your City.                        
                    </p>
                    <p>
                        <a href="http://solarelectricityhandbook.com/solar-angle-calculator.html" target="_blank">
                            Elevation Angle Chart
                        </a>
                    </p>
                </li>
                <li>
                    <strong>Wiring</strong>
                    <p>
                        Since you will be working in only Direct Current (DC) for your solar sign installation, it is important to keep your 
                        wires as short as possible. Use only copper wire with minimum 75˚C insulation rating and between 10 AWG gauge. The 
                        negative system conductor should be properly grounded. 
                    </p>
                </li>
                <li>
                    <strong>Batteries</strong>
                    <p>
                        We suggest a maintenance free battery such as a Gel type or Absorptive Fiberglass Mat glass type, they do not 
                        store as much energy as the Flooded type but you do not have to add water or check on periodically. It is 
                        important that the batteries stay charged if stored in a unheated location. They can freeze if not charged and 
                        this will ruin them.
                    </p>
                    <ul>
                        <li>
                            Link: <a href="http://www.optimabatteries.com/en-us/bluetop-dual-purpose-deep-cycle-and-starting/d31m" target="_blank">Battery we have used</a>
                        </li>
                    </ul>
                </li>
            </ul>

            <h3>Installation</h3>
            <p>
                The physical installation of your sign will be the same as it would be if the sign was not Solar Powered. The 
                solar conversion of your sign from Conventional Electric AC line voltage to Solar DC is very simple.
                There are one or more AC to 12VDC Power Supplies inside the sign when you receive it. You are going to bypass 
                the power supplies with your wire from the Morningstar Sunlight Controller. We can walk you through this if you 
                are unfamiliar with this type of thing.                            
            </p>
            <p>
                Your sign will then be controlled by the Morningstar Sunlight Controller. On the controller you can determine when 
                your sign turns and off based on how much sunlight is hitting the solar panel. You can also control how long the 
                sign stays on a night so to not drain your battery by staying on late in the evening when you are not get any benefit 
                from it.                        
            </p>
		</SupportPage>
	)
}

export default SolarPage